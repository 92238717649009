@import "_variables.scss";

* {
    box-sizing: border-box;
 //   font-family: 'vodaphone';
}
// base stuff that can be migrated to core
.uk-form-horizontal.uk-form-horizontal--tight {
    .uk-form-label {
        width: calc(var(--global-margin) * 5);
    }
    .uk-form-controls {
        margin-left: calc(var(--global-margin) * 5.5);
    }
}

.radios-horizontal .input-inner-wrapper {
    display: flex;

    > * {
        margin-inline-end: calc(var(--global-margin) * 0.5);
        min-width: calc(var(--global-margin) * 4);
    }
}

// flex gap support
.uk-flex-gap-s, .uk-flex-gap-small,
.uk-gap-s, .uk-gap-small {
    gap:  $global-small-margin;
}
.uk-flex-gap-m, .uk-flex-gap-medium ,.uk-flex-gap,
.uk-gap-m, .uk-gap-medium ,.uk-gap {
    gap:  $global-margin;
}
.uk-flex-gap-l, .uk-flex-gap-large,
.uk-gap-l, .uk-gap-large {
    gap:  $global-large-margin;
}

// flex break support
@mixin flex-break-small {
    @media (max-width: $breakpoint-small) {
        flex-wrap: wrap;


        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@mixin flex-break-medium {
    @media (max-width: $breakpoint-medium) {
        flex-wrap: wrap;

        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@mixin flex-break-large {
    @media (max-width: $breakpoint-large) {
        flex-wrap: wrap;

        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@mixin flex-break-xlarge {
    @media (max-width: $breakpoint-xlarge) {
        flex-wrap: wrap;

        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.flex-break-small-start {
    @include flex-break-small;
    @media (max-width: $breakpoint-small) {
        justify-content: flex-start;
    }
}

.flex-break-small-center {
    @include flex-break-small;
    @media (max-width: $breakpoint-small) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-medium-end {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: flex-end;
    }
}

.flex-break-medium-start {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: flex-start;
    }
}

.flex-break-medium-center {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-medium-end {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: flex-end;
    }
}

.flex-break-large-start {
    @include flex-break-large;
    @media (max-width: $breakpoint-large) {
        justify-content: flex-start;
    }
}

.flex-break-large-center {
    @include flex-break-large;
    @media (max-width: $breakpoint-large) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-large-end {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-large) {
        justify-content: flex-end;
    }
}

.flex-break-xlarge-start {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-xlarge) {
        justify-content: flex-start;
    }
}

.flex-break-xlarge-center {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-xlarge) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-xlarge-end {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-xlarge) {
        justify-content: flex-end;
    }
}

.uk-flex-stretch {
    align-items: stretch;
}

.ui-blocker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    background: var(--global-background);
    opacity: 0.5;
    cursor: not-allowed;
}

.uk-background-success {
    background-color: var(--global-success-background)
}

.uk-background-danger {
    background-color: var(--global-danger-background)
}


.uk-success-style {
    background-color: var(--global-primary-success-background);
    color: var(--global-inverse-color);
}

.uk-text-inverse-color {
    color: var(--global-inverse-color);
}

.uk-background-inverse {
    background-color: var(--global-inverse-color);;
}

.uk-events-none {
    pointer-events: none;
}

.uk-text-underline {
    text-decoration: underline;
}

.uk-text-semibold {
    font-weight: 500;
    font-weight: 600;
}

.uk-card-hat {
    padding-top: calc(var(--global-margin) * 0.65);
    padding-bottom: calc(var(--global-margin) * 0.65);
    padding-left: var(--global-margin);
    padding-right: var(--global-margin);
    margin-bottom: var(--global-margin);
    font-size: calc(var(--global-font-size) * 1);
    font-weight: bold;
    border-radius: var(--border-radius-small) var(--border-radius-small) 0 0 ;

}
.uk-border-radius {
    border-radius: var(--border-radius-large);
}

.uk-border-radius-small {
    border-radius: var(--border-radius-small);
}

.round-bottom {
    border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
}

.round-bottom-large {
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
}

.round-bottom-top {
    border-radius: var(--border-radius-small) var(--border-radius-small)  0 0 ;
}

.round-bottom-top-large {
    border-radius: var(--border-radius-large) var(--border-radius-large)  0 0;
}


// end base stuff


// specifics for billrun portal
.decorated-title {
    position: relative;
    display: flex;
    flex-wrap: wrap;



    .title-link {
        font-size: calc(var(--global-font-size) * 1.1);
        margin-inline-start: auto;

    }

    &::after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 0;
        width: 50%;
        max-width: 115px;
        height: 2px;
        background-image: linear-gradient(to right, $global-primary-background 50%, transparent 100%);
    }

    &.decorated-title-center {
        text-align: center;

        .sub-title {
            position: relative;
            top: -4px;
            margin: 0;
            font-size: calc(var(--global-font-size) * 1);
        }
        &::after {
            content: ' ';
            position: absolute;
            bottom: 3px;

            left: 0;
            width: 100%;
            max-width: 100%;
            height: 2px;
            background-image: linear-gradient(to right, transparent 16%, $global-primary-background 27%, $global-primary-background 73%, transparent 84%, transparent 100%);
        }

    }



}

.uk-notification-top-center {
    margin-top: 80px;
}
// end specifics for billrun portal



// specifics for the selected color variables
.uk-text-inverse {
    color: var(--global-inverse-color);
}
.uk-light,
.uk-section-primary:not(.uk-preserve-color),
.uk-section-secondary:not(.uk-preserve-color),
.uk-tile-primary:not(.uk-preserve-color),
.uk-tile-secondary:not(.uk-preserve-color),
.uk-overlay-primary,
.uk-offcanvas-bar {
    color: $global-color;
}

.uk-offcanvas-bar .uk-hr {
    background: $global-secondary-background;
}

.uk-offcanvas-bar .uk-button-secondary {
    background: $global-secondary-background;
    color: $global-background;

    &:hover {
        background: $global-primary-background;
        color: $global-background;
    }
}

.uk-offcanvas-bar .uk-button-primary {
    background: $global-primary-background;
    color: $global-background;

    &:hover {
        background: $global-secondary-background;
        color: $global-background;
    }
}

.uk-card-primary.uk-card-body,
.uk-card-primary > :not([class*="uk-card-media"]),
.uk-card-secondary.uk-card-body,
.uk-card-secondary > :not([class*="uk-card-media"])  {
    color: $global-background;
}
// color fixes end

// specifics for VFP
.nav-lang-switcher  {

    .language-switcher-widget .lang-select-popup-card {
        border-radius:  0 0 var(--border-radius-small) var(--border-radius-small);
    }
}

// on off toggles. specific for VFP. can be made parameterized and moved to core
.oot__controls-line {
    border-radius: var(--border-radius-large);
}

.oot__controls-knob {
    border-radius: var(--border-radius-small);
}
// oot end




.uk-text-inverse {
    color: var(--global-inverse-color);
}

.uk-form-horizontal.uk-form-horizontal--tight {
    .uk-form-label {
        width: calc(var(--global-margin) * 5);
    }
    .uk-form-controls {
        margin-left: calc(var(--global-margin) * 5.5);
    }
}

.radios-horizontal .input-inner-wrapper {
    display: flex;

    > * {
        margin-inline-end: calc(var(--global-margin) * 0.5);
        min-width: calc(var(--global-margin) * 4);
    }
}

// aux
/*

                      $global-small-margin !default;
                    global-medium-margin !default;
                     $global-medium-margin !default;
                   $global-large-margin !default;
                    $global-large-margin !default;
               $global-xlarge-margin !default;
 */

// datepicker
[dir=rtl] .vc-arrow,
[dir=rtl] .vc-nav-arrow{
    transform: rotate(180deg);
}

