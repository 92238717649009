

//define all "hardcoded" framework variables as css variables,
// NOTICE: due to color interpolation, some vars MUST still be SCSS vars
:root, #app, html {
    --global-font-size: 16px;
    --global-margin: 20px;
    --global-inverse-color: #fff;
    --global-color: #3a3a3a;
    --global-muted-background: #f8f8f8;
    --global-primary-background: #4e469c;
    --global-primary-background-dark: #433c87;
    --global-success-background: #a8b400;
    --global-warning-background: #eb9700;
    --global-danger-background: #F30000;
    --global-muted-color: #999;
    --global-background: #fff;
    --global-secondary-background: #222;
    --global-link-color: #4e469c;
    --global-link-hover-color: #433c87;
    --base-ins-background: #ffd;
    --base-mark-background: #ffd;
    --global-border: #e5e5e5;
    --base-selection-background: #4e469c;
    --lightbox-background: #000;
    --lightbox-item-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-background: rgba(0, 0, 0, 0.3);
    --lightbox-toolbar-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-icon-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-icon-hover-color: #fff;
    --lightbox-button-color: rgba(255, 255, 255, 0.7);
    --lightbox-button-hover-color: #fff;
    --modal-background: rgba(0, 0, 0, 0.6);
    --table-row-active-background: #ffd;
    --tooltip-background: #666;
    --box-shadow-bottom-background: #444;

    --dragover-box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);

    --global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    --global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    --global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    --global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
    --dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    --navbar-dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    --navbar-dropbar-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.05);






    //// refactor - vfp vars
    --global-medium-gray: #ddd;
    --accent-color-1: #3a98d4;
    --accent-color-2: #8f649c;
    --accent-color-3: #e77d1e;
    --facebook-color: #3b5998;

    --border-radius-small: 0;
    --border-radius-large: 0;


    --global-success-color: #66ad03;
    --global-warning-color: #eb9700;
    --global-danger-color: #e60000;

    --global-small-gutter: 15px;

    --global-topup-1: #2eb9ce;
    --global-topup-2: #a8b400;
    --global-topup-3: var(--global-primary-background);
    --global-topup-4: #f2be36;
    --global-topup-5: #a82b76;

    --wave-red : #F30000;
    --global-muted-background-dark: #e9e9e9;
    --global-muted-background-light: #f2f2f2;
}


//now, set the framework SCSS vars, to use the css variables. this way, app can implement themes!
$global-inverse-color: #fff;
$global-color: #3a3a3a;
$global-muted-background: #f8f8f8;
$global-primary-background: #4e469c;
$global-success-background: #66ad03;//#a8b400;
$global-warning-background: #eb9700;
$global-danger-background: #e60000;
$global-muted-color: #999;
$global-background: #fff;
$global-secondary-background: #222;
$global-border: #e5e5e5;
$global-margin: 20px;

$global-link-color: var(--global-link-color);
$global-link-hover-color: var(--global-link-hover-color);
$base-ins-background: var(--base-ins-background);
$base-mark-background: var(----base-mark-background);

$base-selection-background: var(--base-selection-background);
$lightbox-background: var(--lightbox-background);
$lightbox-item-color: var(--lightbox-item-color);
$lightbox-toolbar-background: var(--lightbox-toolbar-background);
$lightbox-toolbar-color: var(--lightbox-toolbar-color);
$lightbox-toolbar-icon-color: var(--lightbox-toolbar-icon-color);
$lightbox-toolbar-icon-hover-color: var(--lightbox-toolbar-icon-hover-color);
$lightbox-button-color: var(--lightbox-button-color);
$lightbox-button-hover-color: var(--lightbox-button-hover-color);
$modal-background: var(--modal-background);
$table-row-active-background: var(--table-row-active-background);
$tooltip-background: var(--tooltip-background);
$box-shadow-bottom-background: var(--box-shadow-bottom-background);
$dragover-box-shadow: var(--dragover-box-shadow);
$global-small-box-shadow: var(--global-small-box-shadow);
$global-medium-box-shadow: var(--global-medium-box-shadow);
$global-large-box-shadow: var(--global-large-box-shadow);
$global-xlarge-box-shadow: var(--global-xlarge-box-shadow);
$dropdown-box-shadow: var(--dropdown-box-shadow);
$navbar-dropdown-box-shadow: var(--navbar-dropdown-box-shadow);
$navbar-dropbar-box-shadow: var(--navbar-dropbar-box-shadow);


//$global-font-family: "vodaphone", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
//$global-font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;


@import "~uikit/src/scss/variables-theme.scss";
